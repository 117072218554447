<template lang="pug">
#Invitation
    loadingOverlay(v-show='loading' message='Sending Email . . .' :zIndex='999')
    v-card.noteDialog
        v-card-title.d-flex.justify-center.mt-2 {{$t('TRY_IT_FREE.TITLE')}}
        v-card-text
            v-form(v-model='valid' ref='form')
                v-row.px-0
                    .pr-3.pb-2
                        .input-group.mb-4
                            input.form-control(v-model='invitation.last_name' :placeholder=`$t('TRY_IT_FREE.LAST_NAME')` )
                    .pl-3.pb-2
                        .input-group.mb-4
                            input.form-control(v-model='invitation.first_name' :placeholder=`$t('TRY_IT_FREE.FIRST_NAME')`)
                .input-group.mb-3
                    input.form-control(v-model='invitation.company' type='text', :placeholder=`$t('TRY_IT_FREE.ORG_NAME')`)
                .input-group.mb-3
                    input.form-control(v-model='invitation.role' type='text', :placeholder=`$t('TRY_IT_FREE.ROLE')`)
                .input-group.mb-3
                    input.form-control(v-model='invitation.email' type='email', placeholder='*E-Mail' inputmode='email' @blur='validateEmail')
                .input-group.mb-3
                    input.form-control(v-model='invitation.phone' type='tel', :placeholder=`$t('TRY_IT_FREE.PHONE')` inputmode='tel')
                .input-group.mb-3
                    textarea.form-control(v-model='invitation.message' name="", cols="30", rows="5", :placeholder=`$t('TRY_IT_FREE.MESSAGE')`)
        v-card-actions.px-6.mb-5
            v-row(no-gutters)
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='verifyCaptcha($event)' width='100%' height='40' color='primary' depressed dark) {{$t('GENERAL.CONFIRM')}}
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import errorDialog from "@/components/Dialog/errorDialog";
    import InvitationDataService from '@/services/InvitationDataService';
    import i18n from '/common/plugins/vue-i18n.js'

    export default Vue.extend({
        components: {
            loadingOverlay,
            errorDialog,
        },        
        data() {
            return {
                valid: true,
                loading: false,
                invitation: 
                {   
                    first_name: '',
                    last_name: '',
                    company: '',
                    role: '',
                    email: '',
                    phone: '',
                    message: ''
                },
                errorDialog: false,
                errorMessage: ''
            };
        },
        mounted() {
            let externalScript = document.createElement('script');
            let recaptchaApiUri = `https://www.google.com/recaptcha/api.js?render=${this.getRecaptchaSiteKey()}`;
            externalScript.setAttribute('src', recaptchaApiUri);
            document.head.appendChild(externalScript);
        },
        methods: {
            getRecaptchaSiteKey() {
                return process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
            },
            verifyCaptcha(e) {
                e.preventDefault();
                /*global grecaptcha*/
                /*eslint no-undef: "error"*/
                grecaptcha.ready(() => {
                    grecaptcha
                    // Don't forget about putting your unique SECRET_SITE_KEY.
                    .execute(this.getRecaptchaSiteKey(), {
                        action: "submit"
                    })
                    .then(token => {
                        // Try loggin the token first time to see if it got generated.
                        // console.log(token);
                        // Add your logic to submit to your backend server here.
                        // Here we will send the token to our backend using axios (install and import it to the component).
                        // You could also use a Vue built in $http
                        const body = { token: token };
                        InvitationDataService.verifyCaptcha(body)
                        // We are POSTing to a dedicated URL we have on backend (it can be any URL that will need the reCAPTCHA token)
                        .then(response => {
                            // THe is_human part is our custom parameter comming from our backend. You can call it other name or just return something completly different. This is 100% to developer how will the reCAPTCHA returned probability be used.
                            if (response.data.is_human) {
                            // Your front-end logic fired-up on success.
                                this.onConfirm();
                            } else {
                            // Your front-end logic fired-up on error.
                                this.errorMessage = 'Failed reCaptcha Check';
                                this.errorDialog = true;
                            }
                        })
                        .catch(() => {
                            this.errorMessage = 'Failed reCaptcha Check';
                            this.errorDialog = true;
                        });
                    });
                });
            },
            validateEmail() {
                let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                let isValid = this.invitation.email.match(mailformat);
                if (!isValid) {
                    this.errorMessage = i18n.t('TRY_IT_FREE.ERROR');
                    this.errorDialog = true;
                    return false;
                } else {
                    this.errorMessage = '';
                    return true;
                }
            },
            validateName() {
                let nameformat = /^.{1,50}$/;
                let isValidFN = this.invitation.first_name.match(nameformat);
                let isValidLN = this.invitation.last_name.match(nameformat);
                
                if(!isValidLN){
                    this.errorMessage = i18n.t('RULE.RULE_R_LASTNAME_2');
                    this.errorDialog = true;
                    return false;
                }
                else if (!isValidFN) {
                    this.errorMessage = i18n.t('RULE.RULE_R_FIRSTNAME');
                    this.errorDialog = true;
                    return false;
                } 
                else if(isValidFN && isValidLN){
                    this.errorMessage = '';
                    return true;
                }
            },
            initialize() {
                // initialize data
                this.invitation = 
                {   
                    first_name: '',
                    last_name: '',
                    company: '',
                    role: '',
                    email: '',
                    phone: '',
                    message: ''
                };
            },
            onCancel() {
                this.$emit('emitInvitationDialog', false);
            },
            async onConfirm() {
                if (this.validateEmail() && this.validateName()) {
                    this.loading = true;
                    // send email
                    await InvitationDataService.create(this.invitation)
                    .then(() => {
                        this.loading = false;
                        this.initialize();
                        this.$emit('emitThankYouDialog', i18n.t('TRY_IT_FREE.MESSAGE_2'));
                    });
                }
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },	            
        }
    });
</script>