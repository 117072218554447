<template lang="pug">
#ForgetPassword
    loadingOverlay(v-show='loading' message='Sending Email . . .' :zIndex='999')
    v-card
        v-card-title.d-flex.justify-center.mt-2 {{$t('FORGET_PASSWORD.TITLE')}}
        v-card-text
            form
                .input-group.mb-5
                    input.form-control(v-model='forget_tenant' type='text' :placeholder=`$t('FORGET_PASSWORD.TENANT')`)
                .input-group
                    input.form-control(v-model='forget_email' type='email' :placeholder=`$t('FORGET_PASSWORD.MAIL')` inputmode='email' @blur='validateEmail')
        v-card-actions.px-6.mb-5
            v-row(no-gutters)
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='verifyCaptcha($event)' width='100%' height='40' color='primary' depressed dark) {{$t('GENERAL.CONFIRM')}}
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import errorDialog from "@/components/Dialog/errorDialog";
    import AccountDataService from "../../../services/AccountDataService";
    import InvitationDataService from '@/services/InvitationDataService';
    import i18n from '/common/plugins/vue-i18n.js' 

	export default Vue.extend({
        components: {
            loadingOverlay,
            errorDialog,
        },        
        data() {
            return {
                loading: false,
                forget_tenant: '',
                forget_email: '',
                forgetPassword: false,
                errorDialog: false,
                errorMessage: '',
            };
        },
        mounted() {
            let externalScript = document.createElement('script');
            let recaptchaApiUri = `https://www.google.com/recaptcha/api.js?render=${this.getRecaptchaSiteKey()}`;
            externalScript.setAttribute('src', recaptchaApiUri);
            document.head.appendChild(externalScript);
        },
        methods: {
            getRecaptchaSiteKey() {
                return process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
            },
            verifyCaptcha(e) {
                e.preventDefault();
                /*global grecaptcha*/
                /*eslint no-undef: "error"*/                
                grecaptcha.ready(() => {
                    grecaptcha
                    // Don't forget about putting your unique SECRET_SITE_KEY.
                    .execute(this.getRecaptchaSiteKey(), {
                        action: "submit"
                    })
                    .then(token => {
                        // Try loggin the token first time to see if it got generated.
                        console.log(token);
                        // Add your logic to submit to your backend server here.
                        // Here we will send the token to our backend using axios (install and import it to the component).
                        // You could also use a Vue built in $http
                        const body = { token: token };
                        InvitationDataService.verifyCaptcha(body)
                        // We are POSTing to a dedicated URL we have on backend (it can be any URL that will need the reCAPTCHA token)
                        .then(response => {
                            // THe is_human part is our custom parameter comming from our backend. You can call it other name or just return something completly different. This is 100% to developer how will the reCAPTCHA returned probability be used.
                            if (response.data.is_human) {
                            // Your front-end logic fired-up on success.
                                this.onConfirm();
                            } else {
                            // Your front-end logic fired-up on error.
                                this.errorMessage = 'Failed reCaptcha Check';
                                this.errorDialog = true;
                            }
                        })
                        .catch(() => {
                            this.errorMessage = 'Failed reCaptcha Check';
                            this.errorDialog = true;
                        });
                    });
                });
            },            
            validateEmail() {
                let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                let isValid = this.forget_email.match(mailformat);
                if (!isValid) {
                    this.errorMessage = i18n.t('TRY_IT_FREE.ERROR');
                    this.errorDialog = true;
                    return false;
                } else {
                    this.errorMessage = '';
                    return true;
                }
            },
            onCancel() {
                this.$emit('emitForgetPasswordDialog', false);
            },
            async onConfirm() {
                if (this.validateEmail()) {
                    this.loading = true;
                    var data = { 'tenant': this.forget_tenant, 'email': this.forget_email };
                    let fail = false;
                    await AccountDataService.forgetPassword(data)
                    .catch(()=>{
                        fail = true;
                        this.$emit('emitForgetPasswordSuccess', false, i18n.t('FORGET_PASSWORD.ERROR'));
                    }).finally(()=>{
                        this.forgetPassword = false;
                        this.forget_tenant = '';
                        this.forget_email = '';
                        this.loading = false;
                        if(!fail){
                            this.$emit('emitForgetPasswordSuccess', true);
                        }
                    });
                }
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },	            
		}
	});
</script>